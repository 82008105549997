/* global $ */
class Transitions {
	constructor(options = {}) {
		this.siteWrapper = (options.siteWrapperId ? $("#" + options.siteWrapperId + "") : $("#site"));

		console.log("%c\u2713 %cTransitions %cModule %cis installed","font-size:2em","font-weight:bold; font-size:1.5em;color: #fd7e14;"," color: #444; font-weight:bold; font-size:1.5em;","font-weight:normal; font-size:1em;");
	}

	showSiteContent() {
		this.siteWrapper.fadeIn(300);
	}

	hideSiteContent() {
		this.siteWrapper.fadeOut(300);
	}

	redirectPage($target) {
		window.location.href = $target.attr("href");
	}
}